@import '../../_variables.scss';

.hamburger {
  width: 40px;
  height: 40px;
  background-color: $secondaryBackground;
  border-radius: $borderRadiusMain;
  flex-shrink: 0;
  margin-left: $spacingBasePlus;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 16.8px;
    height: 13.6px;
    background-color: #FFF;
    mask: url(/images/icons/hamburger.svg) no-repeat center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.unread:before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $contrastAccentColor;
    top: -1px;
    right: -3px;
    border-radius: 50%;
  }
}