@import '../../_variables.scss';

.userMenu {
  .mobileHeader {
    display: flex;
    position: relative;
    margin: $spacingSmall auto $spacingBasePlus;
    align-items: center;
    justify-content: space-between;

    .image {
      & > div {
        display: block !important;
      }
    }

    .close {
      width: 40px;
      height: 40px;
      background-color: $secondaryBackground;
      border-radius: $borderRadiusMain;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      top: -3px;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 16.8px;
        height: 13.6px;
        background-color: #FFF;
        mask: url(/images/icons/close.svg) no-repeat center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .link {
    font-size: $fontSizeBasePlus;
    margin-bottom: $spacingSmall;

    a,
    div {
      color: #FFF;
      cursor: pointer;

      span {
        background-color: $contrastAccentColor;
        border-radius: $borderRadiusMain;
        font-size: 12px;
        font-weight: 600;
        margin-left: $spacingMicro;
        position: relative;
        top: -2px;
        padding: 1px 8px;
      }
    }

    &.active {
      a,
      div {
        color: $primaryColor;
      }
    }
  }

  button {
    margin-top: $spacingBase;
  }
}